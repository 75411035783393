"use client";
import { getToken } from "@/libs/token/token";
import { useRouter } from "next/navigation";
import { useEffect } from 'react';

export default function Home() {
    const router = useRouter();

    useEffect(() => {
        if (getToken()) {
            router.push("/dashboard");
        } else {
            router.push("/login");
        }
    }, [])

    return null;
}
