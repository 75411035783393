export enum JWTKeys {
    ADMIN = "JWT_BALEEK__ADMIN_PORTAL_TOKEN"
}

export interface IToken {
    accessToken: string;
    refreshToken: string;
    jti: string;
}

export const getToken = (prop: keyof IToken = 'accessToken') => {
    let token;
    try {
        const storage = localStorage?.getItem(JWTKeys.ADMIN);
        const parsedStorage: IToken = storage && JSON.parse(storage);
        token = parsedStorage[prop];
    } catch (err) {
        token = null;
    }
    return token;
};

export const keepToken = (token: IToken, key = JWTKeys.ADMIN) => {
    localStorage?.setItem(key, JSON.stringify(token));
};
export const updateAccessToken = (token: string, key = JWTKeys.ADMIN) => {
    const storage = localStorage?.getItem(key);
    const parsedStorage: IToken = storage && JSON.parse(storage);
    parsedStorage.accessToken = token;
    localStorage?.setItem(key, JSON.stringify(parsedStorage));
};
export const forgetToken = () => {
    localStorage?.removeItem(JWTKeys.ADMIN);
};
// export const readToken = (token: string) => JWT.read(token);
export const validateToken = (token: string) => {
    return !!getToken();
};
